<template>
<div>
  <Navbar title="隐私协议" type="3" />
  <pre class="markdown-body container">
    <h3>《瑞升达隐私保护政策》</h3>
    瑞升达科技有限公司（以下简称“瑞升达”或者我们）遵守并保护用户（以下简称您）的隐私。您在使用我们的服务时，我们将按照瑞升达隐私保护政策（以下简称“本政策”）收集、存储、 使用及对外提供您的个人信息。同时，我们会通过本政策向您说明，我们如何为您提供访问、更新、管理和保护您的信息的服务。本政策与您使用我们的服务关系紧密，我们建议您仔细阅读并理解本政策全部内容，做出您认为适当的选择。为了遵守国家法律法规及监管规定（例如：进行实名制管理、履行反洗钱职责、采取风险防范措施），也为了向您提供服务及提升服务质量（例如：支持我们开发新产品或完善已有产品功能，为您提供和推荐更为优质或适合的服务），我们需要收集、存储、使用及对外提供您的信息。您同意我们按照本政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账户及资金安全。 一、 瑞升达如何收集您的个人信息在您使用瑞升达服务的过程中，我们需要收集您的一些信息，用以向您提供服务、提升我们的服务质量、保障您的账户安全以及符合国家法律法规及监管规定：
    1、依据法律法规及监管规定进行实名制管理。
    （1）在您注册瑞升达账户或使用我们服务时，您需提供您的有效身份证件信息、联系方式、地址。如您不提供前述信息，可能无法注册瑞升达账户或无法使用我们的服务。为了验证您提供信息的准确性和完整性，我们会与合法留存您的信息的征信机构或者其他第三方机构进行核对；如在验证核对过程中我们需要向前述验证机构收集您的信息，我们会依照法律法规的规定要求相关验证机构说明其个人信息来源，并对其个人信息来源的合法性进行确认；
    2、风险防范
    为了提高您使用我们服务的安全性，防止您的资金、个人信息被不法分子获取，我们需要记录您使用我们的服务类别、方式及相关操作信息，例如：设备型号、IP地址、设备软件版本信息、设备识别码、设备标识符、所在位置、网络使用习惯以及其他与服务相关的日志信息。如您不同意提供前述信息，可能无法完成风控验证。
    3、为了提供个性化服务及改进服务质量
    为提升您的服务体验及改进服务质量，或者为您推荐更优质或更适合的服务：
    （1）您可以选择使用麦克风设备来进行语音输入，在使用过程中我们需要收集您的语音内容并进行必要的处理；
    （2）您可以授权提供您的位置信息，以便您基于所在地点位置接受个性化服务推荐；
    （3）我们会收集您使用瑞升达服务时的搜索记录、您向我们提供的信息及您参与问卷调查时向我们发送的信息。
    4、根据相关法律法规，我们可能会依法收集并使用您的个人信息无需征得您的同意：
    （1）与国家安全、国防安全直接相关的；
    （2）与公共安全、公共卫生、重大公共利益直接相关的；
    （3）与刑事司法等直接相关的；
    （4）出于维护您或他人的生命、财产等重大合法权益但又很难得到您本人同意的；
    （5）所收集的个人信息是您自行向社会公众公开的；
    （6）从合法公开披露的信息中收集个人信息；
    （7）根据您的要求签订和履行合同所必需的；
    （8）用于维护所提供的服务的安全稳定运行所必需的，例如：检测和处理服务故障；
    （9） 法律法规规定的其他情形。 5 、其他
    我们在向您提供其他业务功能时，会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息。我们会按照本政策以及相应的用户协议约定使用、存
    储、对外提供及保护您的信息。
    6、第三方 sdk 说明 com.tencent.smtt(TBS腾讯浏览服务;腾讯浏览服务;腾讯 X5 浏览器;腾讯浏览器) --展示活动 详情业务 com.alipay(支付宝;阿里乘车码;阿里芝麻信用实名认证;芝麻认证)--根据芝麻认证选择业务 com.tencent.bugly(Bugly;腾讯 Bugly)--腾讯系统崩溃日志收集 cn.jpush.android(极光;极光推送) --推送推广消息用com.baidu.location(百度地图) --定位服务 com.qq.e.ads(广点通;腾讯社交)--广告服务 com.qq.e.comm(广点通;腾讯社交) --获取广告身份服务 com.m7.imkfsdk --客服 com.webank.facelight --活动检测 获取用户的 IMEI、MAC 地址目的：确定用户唯一身份 获取软件安装列表 目的：与其他 app 交互用
    二、
    瑞升达如何使用 Cookie 等技术 为使您获得更轻松的访问体验，您访问我们 APP或使用我们提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。这些数据文件可能是Cookie，您的浏览器或关联应用程序提供的其他本地存储（以 下简称“Cookie”）。请您理解，我们的某些服务只能通过使用 Cookie 才可得到实现。如您的浏览器或浏览器附加服务允许，您可以修改对 Cookie 的接受程度或者拒绝瑞升达的Cookie。
    三、 瑞升达如何存储和保护您的个人信息
    1、我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。
    2、为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。同时我们设立了个人信息保护责任部门，建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导。另外，我们还会聘请外部独立第三方对我们的信息安全管理体系进 行评估。
    3、请您务必妥善保管好您的账户及其他身份要素。您在使用我们的服务时，我们会通过您的账户及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能产生对您不利的法律后果。如您发现账户及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
    四、瑞升达如何使用您的个人信息
    1、为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户和资金安全，我们会在以下情形中使用您的信息：
    （1）实现本政策中所述目的；
    （2）为了使您知晓使用我们服务的状态，我们会向您发送服务提醒。
    （3）为了保障服务的稳定性与安全性，我们会将您的信息用于身份验证、安全防范、诈骗监测、
    预防或禁止非法活动、降低风险、存档和备份用途；
    （4）根据法律法规或监管要求向相关部门进行报告；
    （5）邀请您参与我们产品或服务有关的客户调研；
    （6）我们会对您的信息进行综合统计、分析加工，以便为您提供更加准确、个性、流畅及便捷的服务，或帮助我们评估、改善或设计产品、服务及运营活动等。我们可能根据前述信息向您 提供营销活动通知、商业性电子信息或您可能感兴趣的广告，如您不希望接收此类信息，您可联系我们退订。
    2、当我们要将信息用于本政策未载明的其他用途时，会再次征求您的同意。
    五、瑞升达如何对外提供您的个人信息
    1 、共享
    （一）业务共享
    我们承诺对您的信息进行保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括我们的关联公司、以及其他合作伙伴。在将信息提供给第三方前，我们将尽商业上合理的努力评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规，尽力要求第三方对您的信息采取保护措施。
    （1）为了让您完成交易、实现交易目的，我们需要向您的交易相对方提供交易相关信息，同时您的交易相对方可查看您的部分注册信息；
    （2）某些产品或服务可能由第三方提供或由我们与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或服务。
    （3）如您选择参与我们和第三方联合开展的推广活动，我们可能与其共享活动过程中产生的、为完成活动所必要的信息，以便第三方能及时为您提供服务。
    （4）事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。
    （二）第三方登录
    当您使用瑞升达账户登录第三方网站或 APP时，请您务必仔细阅读第三方的用户服务协议或隐私政策，我们会依据您与第三方的约定，将您的部分信息提供给第三方。
    （三）投诉处理
    当您投诉他人或被他人投诉时，为了保护您及他人的合法权益，我们可能会将您的姓名及有效证件号码、联系方式、投诉相关内容提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。
    2 、转让
    我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    （1）事先获得您的明确同意；
    （2）根据法律法规或强制性的行政或司法要求；
    （3）在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。 3 、公开披露除在公布中奖活动名单时会脱敏展示中奖者手机号或账户名外，原则上我们不会将您的信息进行公开披露。如确需公开披露时，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明确同意。
    4 、委托处理
    为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的关联公司或其他专业机构代表我们来处理用户信息，但我们会通过书面协议、现场监督等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。
    六、 您如何访问和管理您的个人信息
    在您使用我们服务期间，为了您可以更加便捷地访问和管理您的个人信息，同时保障您注销账户的权利，我们在产品设计中为您提供了相应的操作设置，您可以参考下面的指引进行操作。
    1 、管理您的个人信息 您可通过我们 APP 登录瑞升达账户，在 APP 中：
    （1）查阅您的账户信息。
    （2）如发现您的信息有误，您可以联系我们的客服要求更正。出于安全性和身份识别的考虑或根据法律法规及监管规定，您需通过验证方可修改您已认证的姓名或已绑定的手机号码；
    2 、管理您的授权范围
    如您想改变授权范围，您可联系我们取消您的授权。当您取消信息收集的授权后，我们将不再收集该信息；在您取消向第三方共享信息的授权后，我们将不再在该业务场景下向该第三方提供信息。
    3、如您发现我们收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可联系我们的客服，要求删除相应的信息。
    4、尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：
    （1）与国家安全、国防安全直接相关的；
    （2）与公共安全、公共卫生、重大公共利益直接相关的；
    （3）与刑事司法活动等直接相关的；
    （4）有充分证据表明您存在主观恶意或滥用权利的；
    （5）响应您的请求将导致其他个人、组织的合法权益受到严重损害的；
    （6）涉及商业秘密的。
    七、 对第三方责任的声明
    请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务和由瑞升达接收您的个人信息的第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的 Cookie，这些Cookie 不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。
    八、 未成年人隐私权特别约定
    1、我们期望父母或监护人指导未成年人使用我们的服务。我们将根据国家相关法律法规的规定保护未成年人的个人信息的保密性及安全性。
    2、如您为未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。对于经父母或监护人同意而收集您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请 您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
    3、如您为未成年人的父母或监护人，当您对您所监护的未成年人的个人信息处理存在疑问时，请及时联系我们。
    九、 本政策的适用及更新 我们的所有服务均适用本政策。
    发生下列重大变化情形时，我们会适时对本政策进行 更新：
    （1）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；
    （2）收集、存储、使用个人信息的范围、目的、规则发生变化；
    （3）对外提供个人信息的对象、范围、目的发生变化；
    （4）您访问和管理个人信息的方式发生变化；
    （5）数据安全能力、信息安全风险发生变化；
    （6）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；
    （7）其他可能对您的个人信息权益产生重大影响的变化。
    由于瑞升达的用户较多，如本政策发生更新，我们将以 APP推送通知、发送邮件/短消息或者在瑞升达官方网站发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。
  </pre>
</div>
</template>

<script type="text/ecmascript-6">
export default {
  name: 'secrecyAgreement',
  data() {
    return {
    }
  },
  props: {

  },
  methods: {

  },
  computed: {

  },
  components: {

  },
  created() {
  }
}
</script>

<style scoped lang='scss'>
@import '~@/styles/_mixins.scss';
.container {
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 1.5;
  @include safeArea(padding-bottom);
}
</style>
